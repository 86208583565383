// import { useEffect, useState } from "react";
// import { reminderAPI } from "services";
// import useUniversalStore from "store/universalStore";

const ReminderView = ({ account ,remindBool }) => {
    // const [reminderAlert, setReminderAlert] = useState(null);
    // const {isReminderChanged} = useUniversalStore((state)=>state);

    // useEffect(() => {
    //     const fetchReminder = async () => {
    //         try {
    //             const alert = await reminderAPI.fetchRemainder(account.id)
    //             if(alert){
    //                 setReminderAlert(alert?.[0]);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching reminder alert:", error);
    //             setLoading(false);
    //         }
    //     };

    //     fetchReminder();
    // }, [account.id,isReminderChanged]);

    // const nowTime = new Date().getTime();
    // const remindBool =  (nowTime > reminderAlert?.get("date")?.getTime());
    
    if (remindBool) {
        return ( <i className="bi bi-patch-exclamation-fill text-danger me-2" title="Payment Due!"></i> );
    } else {
        return null;
    }
}
export default ReminderView;