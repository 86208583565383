import React, { useState } from 'react';
import useUniversalStore from 'store/universalStore';
//<i className="bi bi-people" style={{"fontSize": 2, "color": cornflowerblue}}></i>

            
function NoKhata(){

    const write = useUniversalStore((state) => state.write);


    // const clickEvent = ()=>write({activeComponent:"newAccount"})
    
    return (
        <div className="nokhata">
            <div className='row h-100'>
                <div className='col-12 my-auto'>
                    <i className="bi bi-people" style={{ fontSize: "7rem", color: "black" }}/>
                    <h3><i className="bi bi-arrow-left" style={{ fontSize: "1.4rem", color: "black" }}/> Ledgers</h3>
                    <p>Select a Khata to get started</p>
                    <button 
                        className="btn btn-app mt-3 animate__animated animate__heartBeat animate__repeat-2 fs-6"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#addAccountDrawer"
                        // onClick={clickEvent}
                        >
                            Create New Khata
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NoKhata;