import React, { useEffect, useState, useCallback, useRef } from "react";
import Calendar from "react-calendar";

const MonthView = (props) => {
  const { onDateChange, display, setAnimation ,loading } = props;
  const currDate = new Date();
  const [date, setDate] = useState(currDate);
  const [showCalendar, setShowCalendar] = useState(false);
  const [nextMonthAccessible, setNextMonthAccessible] = useState(false);
  const calendarRef = useRef(null); // Reference for calendar popup
  const month_name = ["Jan","Feb", "Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

  useEffect(() => {
    const year = date.getFullYear();
    const month = date.getMonth();
    onDateChange(date);

    if (month === currDate.getMonth() && year === currDate.getFullYear()) {
      setNextMonthAccessible(false);
    } else {
      setNextMonthAccessible(true);
    }
  }, [date]);

  const handleDateChange = useCallback((newDate) => {
    setDate(newDate);
    setShowCalendar(false);
  }, []);

  const addMonth = useCallback(() => {
    setDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
    setAnimation("animate__animated animate__slideInRight fast-animation");
  }, []);

  const subMonth = useCallback(() => {
    setDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
    setAnimation("animate__animated animate__slideInLeft fast-animation");
  }, []);

  const isFutureMonth = (month, year) => {
    const currMonth = currDate.getMonth();
    const currYear = currDate.getFullYear();
    return year > currYear || (year === currYear && month > currMonth);
  };

  // Close the calendar if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={display ? "khataFilter rounded-3 d-flex" : "khataFilter rounded-3 d-none"}>
      <div className="h-100 primary d-flex rounded-2">
        <button className="btn btn-sm bg-app fs-4 text-white border-0" onClick={subMonth} disabled={loading}>
         {<i className="bi bi-arrow-left-circle-fill"></i>}
        </button>
        <label
          className="primary text-white h4 my-auto rounded"
          onClick={() => setShowCalendar(!showCalendar)}
        >
          {month_name[date.getMonth()] + `'` + date.getFullYear()}
        </label>
        {showCalendar && (
          <div className="calendar-popup1" ref={calendarRef}>
            <Calendar
              onChange={handleDateChange}
              value={date}
              view="year"
              maxDetail="year"
              showNeighboringMonth={false}
              calendarType="US"
              maxDate={currDate}
              tileClassName={({ date }) => {
                const month = date.getMonth();
                const year = date.getFullYear();
                if (isFutureMonth(month, year)) {
                  return "disabled-month";
                }
                return null;
              }}
            />
          </div>
        )}
        <button
          className="btn btn-sm border-0"
          onClick={addMonth}
          disabled={!nextMonthAccessible || loading}
        >
          {nextMonthAccessible ? (
            <i className="bi bi-arrow-right-circle-fill fs-4 text-white"></i>
          ) : (
            <i className="bi bi-arrow-right-circle fs-4 text-white"></i>
          )}
        </button>
      </div>
    </div>
  );
};

export default MonthView;
