import Parse from "./parse.service";
import { auth } from "services";

const Profile = Parse.Object.extend("Profile");
const user = auth.fetchUser();

export const getProfile = async () => {
    const query = new Parse.Query(Profile);
    query.equalTo("userId", user);
    try {
        const results = await query.find();        
        if(results.length==0){
          let profile = new Profile()
            profile.set("userId", user);
            await profile.save();
            return getProfile();
        }
        
        return results[0];
    } catch (err) {
        return err;
    }
}

export const updateProfile = async (profileData) => {
    try {
        const profile = await getProfile();
        profile.set("userId", user);
        profileData.phone? profile.set("phone", profileData.phone) : null;
        //profileData.email? profile.set("email", profileData.email) : null;
        profileData.businessName? profile.set("businessName", profileData.businessName) : null;
        profileData.typeOfBusiness? profile.set("typeOfBusiness", profileData.typeOfBusiness) : null;
        profileData.photo? profile.set("profilePhoto", profileData.profilePhoto) : null;
        return profile.save();
    } catch(error){
        console.error("Error updating profile:", error);
    }
}

export function setOnboardingInfo(status) {
    user.set('onboardingInfo', status)
    return user.save();
}

export function setTypeOfBusiness(typeOfBusiness) {
    user.set('typeOfBusiness', typeOfBusiness)
    return user.save();
}

export async function uploadImage(file,setProgress) {
    const currentTime = Date.now().toString();
    const name = "Images"+currentTime;

    // Create a timeout promise that rejects after 25 seconds
    const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Timeout: Upload took too long')), 25000)
    );
    try {
        // Use Promise.race to execute both the upload and timeout concurrently
        const result = await Promise.race([
            (async () => {
                var parseFile = new Parse.File(name, file);
                return await parseFile.save({
                    progress: (progressValue, loaded, total, { type }) => {
                        if (type === "upload" && progressValue !== null) {
                            setProgress(progressValue*100);           
                        }
                    },
                    error: (error) => {
                        throw new Error("file upload failed", error);
                    },
                    
                });
            })(),
            timeoutPromise
        ]);

        return result;
    } catch (error) {
        console.log("Error uploading file:", error);
    }
}


