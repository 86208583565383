import { useState, useEffect } from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { accountAPI, reminderAPI, transactionAPI } from "services";
import { ExpandableButton, Loader, ReminderAlert, Status } from "components";
import { NewTxn, EditTxn, EditRTxn } from "views/forms";
import { useLocation } from "react-router-dom";
import useUniversalStore from "store/universalStore";
import Header from "./Header/index";
import Footer from "./Footer";
import TxnsListView from "./TxnsListView";
import "animate.css/animate.min.css";
const accounting = require("accounting");

const Khata = () => {
  const location = useLocation();
  const date = location.state;
  const [month, setMonth] = useState(date ? date : new Date());
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [reminder, setReminder] = useState(null);
  const [hideClosed, setHideClosed] = useState(false);
  const { accounts, accountId, isReminderChanged, write } = useUniversalStore((state) => state);
  const account = accounts.find((acc) => acc.id === accountId);
  
  //TODO : needs to be moved to relevant component
  const [animation, setAnimation] = useState("");

  //const goToNextStep = useOnboardingStore((state) => state.goToNextStep);

  //intially fetch the transctions and if payment overdue then show overLayer blur effect
  useEffect(() => {
    if (accountId && month) {
      fetchTransactions();
    }
    fetchRemainder();
  }, [accountId, month, isReminderChanged]);

  const fetchTransactions = async () => {
    try {
      const results = await transactionAPI.getTransactionsByAccountIdWithMonth(accountId,month);
      write({ transactions: results });
    } catch (error) {
      console.error(error);
    }
  };

  //method to fetch Remainder and add results in global store
  const fetchRemainder = async () => {
    try {
      const response = await reminderAPI.fetchRemainder(accountId);
      if(response){
        setReminder(response[0])
        checkOverdueReminder(response[0]);
      }
    } catch (error) {
      console.log("fetchRemainder error ", error);
    }
  };

  //method to check the overdue reminder
  const checkOverdueReminder = (reminder) => {
    if (reminder) {
      const remainderSheduleTime = reminder?.get("date");
      const reminderEnable = reminder?.get("enabled");
      const paymentOverdue = remainderSheduleTime?.getTime() < Date.now();
      setOverlayVisible(paymentOverdue && reminderEnable);
    } else {
      setOverlayVisible(false);
    }
  };

  const editTxn = (data) => write({ transactionId: data.id });

  /*  const confirmit = (txn) => {
    write((prev) => {
      let tempVar = { ...prev.deleteTransactions };
      tempVar.type = "Transaction";
      tempVar.object = txn;
      return { ...prev, deleteTransactions: tempVar };
    });
    const toDel = { delTxn: txn };
    
    // setDeletionOf(txn);
  };*/

  // const changeMonth = (year, month) => {
  //   let newMonth = new Date(year, month);
  //   setMonth(newMonth);
  // };

  const changeMonth = (date)=>{
    setMonth(date);
  }

  const handleToggle = (value)=>{
    setHideClosed(value);
  }

  if (!account) return <Loader />;

  return (
    <>
      <div className="position-relative">
        {/* Overlay for background blur and disabling interaction */}
        {isOverlayVisible && (
          <div className="overlay d-flex justify-content-center align-items-center">
            <ReminderAlert setOverlayVisible={setOverlayVisible} reminder={reminder} account={account}  />
          </div>
        )}
        <div
          className={`content-container ${isOverlayVisible ? "disabled" : ""}`}
        >
          <Header
            account={account}
            handleNewDate={changeMonth}
            setAnimation={setAnimation}
            month={month}
            // setHideClosed={setHideClosed}
            hideClosed={hideClosed}
            handleToggle={handleToggle}
          />

          <TxnsListView
            hideClosed={hideClosed}
            monthlyDate={month}
            editTxn={editTxn}
            animation={animation}
            setAnimation={setAnimation}
          />
          {/* Only shows button when a valid user exists */}
          <div
            style={{ paddingBottom: 125, paddingRight: 50 }}
            className="position-fixed bottom-0 end-0"
          >
            <ExpandableButton
              title="New Entry"
              clickForm="addTxnDrawer"
              // onClick={(e) => goToNextStep()}
              icon={faPencil}
            />
          </div>

          <div className="sticky-bottom bg-app py-3 px-3 text-white d-flex">
            <h3
              className="my-auto text-left text-md-left"
              title="Account Balance"
            >
              Balance : {accounting.formatMoney(account.get("balance"), "₹", 0)}
            </h3>
            <Footer account={account} />
          </div>
          <NewTxn handleChangeMonth = {changeMonth} />
          <EditTxn handleChangeMonth = {changeMonth} />
          <EditRTxn />
        
          {/* <div className="status-center">
            <Status />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Khata;