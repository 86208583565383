import Parse from "./parse.service";
import { auth } from "./index";

const Account = Parse.Object.extend("Account");
// Accounts

  export const createAccount = async (
    name,
    phone,
    address,
    markup,
    photourl
  ) => {
    const newAccount = new Account();
    newAccount.set("name", name);
    newAccount.set("balance",0);
  
    if (phone) {
      newAccount.set("phone", phone);
    }
    if (address) {
      newAccount.set("address", address);
    }
    if (markup) {
      newAccount.set("markup", markup);
    }
  
    if (photourl) {
      newAccount.set("photo", photourl);
    }
    try {
      newAccount.set("userId", auth.fetchUser());
      newAccount.set("balance",0);
      const savedAccount = await newAccount.save();
      return savedAccount;
    } catch (error) {
      console.error("Error creating account:", error);
      throw new Error("Failed to create account");
    }
  };
  
  export const account = (name, phone) => {
    // if (!getAccountByName(name)|| !getAccountByPhone(phone)) {
    const account = new Account();
    account.set("name", name);
    account.set("balance", 0);
    account.set("phone", phone);
    account.set("cancelled", false);
    account.set("userId", auth.fetchUser());
    console.log(account);
    
    return account.save();
    // }
    //  else console.warn("Error while creating account.");
  }
  
  export  const getAccounts = async () => {
    const currentUser = auth.fetchUser();
    if(!currentUser) return null;
    const query = new Parse.Query(Account);
    // query.include("userId");
    query.equalTo("userId", currentUser);
    query.exclude("userId");
    query.ascending("name");
    query.ascending("cancelled");
    
    query.limit(1000000);
  
    // if (hideClosed) query.equalTo("cancelled", false);
    return await query.find();
  }
  
  export const getActiveAccounts = async () => {
    const query = new Parse.Query(Account);
    query.equalTo("cancelled", false);
    query.ascending("name");
    query.limit(1000000);
  
    query.equalTo("userId", currentUser);
    return await query.find();
  }
  
  export const getAccountByName = async (name) => {
    const query = new Parse.Query(Account);
    query.limit(1);
    query.matches("name", new RegExp(`^${name}$`, "i"));
    query.equalTo("userId", auth.fetchUser());
    const results = await query.find();  
    if (results.length) return results[0];
    else return results.length;
  }

  export const getKathaAccountById = async (accountId) => {
    try {
      const query = new Parse.Query(Account);
      const response = await query.get(accountId);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
 
  export const getAccountById = async (id) => {
    const query = new Parse.Query(Account);
    query.limit(1);
    query.equalTo("objectId", id);
    const results = await query.find();  
    // console.log(results[0]);
    if (results.length != 0) return results[0];
    return null;
  }
  
  export const updateAccount = async (id, name, phone, address, markup, photourl) => {
    var account = await getAccountById(id);
    try {
      if (account.get("name") !== name) {
        account.set("name", name);
      }
      if (account.get("phone") !== phone) {
        account.set("phone", phone);
      }
      if (account.get("address") !== address) {
        account.set("address", address);
      }
      if (account.get("markup") !== markup) {
        account.set("markup", markup);
      }
      if(account.get("photo") !== photourl && photourl){
        
        account.set("photo",photourl);
      }
      await account.save();
      return account;
    } catch (error) {
      console.error("Error updating account:", error);
      throw new Error("Failed to update account");
    }
        
        // const oldProfilePhoto = account.get("photo");
        // console.log('profilePhoto', profilePhoto);
        // if (profilePhoto !== oldProfilePhoto) {
        //   if (profilePhoto === null) {
        //     account.set("photo", null);
        //   } else if (profilePhoto instanceof File) {
        //     const parseFile = new Parse.File(profilePhoto.name, profilePhoto);
        //     account.set("photo", parseFile);
        //   } else if (
        //     oldProfilePhoto &&
        //     oldProfilePhoto._url !== profilePhoto._url
        //   ) {
        //     const parseFile = new Parse.File(profilePhoto.name, {
        //       uri: profilePhoto._url,
        //     });
        //     account.set("photo", parseFile);
        //   }
        // }
      
  }
  
  export const closeAccount = async (id) => {
    try {
      const account = await getAccountById(id);
      account.set("cancelled", true);
      await account.save();
      return true;
    } catch (error) {
      console.error("Error closing account:", error);
      throw new Error("Failed to close account");
    }
  }
  
  export const deleteAccount = async (acc) => {
    return await acc.destroy();
  }
  
  export const reopenAccount = async (id) => {
    try{
      const account = await getAccountById(id);
      account.set("cancelled", false);
      return await account.save();
    } catch (error) {
      console.error("Error reopening account:", error);
      throw new Error("Failed to re-open account");
    }
  }
  
  // export const updateBalance = async (accountid, amount) => {
  //   const myAccount = await getAccountById(accountid);
  //   console.log("myAccount ",myAccount);
  //   if (myAccount) {
  //       myAccount.set("balance", myAccount.get("balance") + amount);
  //       return await myAccount.save();
  //   }
  // }
  
  // Helper function to update the balance of an account
export const updateBalance = async (accountid, amount) => {
  try {
    const myAccount = await getAccountById(accountid);
    if (myAccount) {
      myAccount.set("balance", myAccount.get("balance") + amount);
      const resposne = await myAccount.save();
      // return await myAccount.save();
      return resposne;
    } else {
      throw new Error("Account not found.");
    }
  } catch (error) {
    console.error("Error updating balance: ", error);
    throw error;
  }
}

export const updateAccountProfile = async (accountId,file) => {
    const name = file.name;
    try {
        const account = await getAccountById(accountId);
        var parseFile = new Parse.File(name, file);
        const result = await parseFile.save();
        account.set("photo",result._url)
        await account.save();
        return result;
    } catch (error) {
        console.log("error upload file error",error);
        
    }
  }
  
