import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { accountAPI, api, auth, transactionAPI } from "services";
import Txn from "./Txn";
import OpenCloseBalance from "components/OpenCloseBalance";
import MonthView from "./MonthView";
import { Loader } from "components";
import ModalImage from "react-modal-image";
const accounting = require("accounting");

const PublicKhata = () => {
  // Retrieve account ID from URL parameters
  const { id } = useParams();

  // State variables to hold various data
  const [txns, setTxns] = useState([]);  // Store transactions
  const [account, setAccount] = useState(null);  // Store account details
  const [selectedMonth, setSelectedMonth] = useState(new Date());  // Store selected month for filtering transactions
  const [openBalance, setOpenBalance] = useState(0);  // Store opening balance for the selected month
  const [closeBalance, setCloseBalance] = useState(0);  // Store closing balance for the selected month
  const [loading, setLoading] = useState(true);  // State to manage loading state
  const [reportLoading, setReportLoading] = useState(false);  // State to manage report loading state
  const [animation, setAnimation] = useState("");  // Animation class for the transaction view
  const [profileUrl,setProfileUrl] = useState("");
  
  
  // Fetch account details by account ID
  const fetchData = async () => {
    try {
      const result = await accountAPI.getAccountById(id);
      if (result) {
        setAccount(result);  // Store account details in state
      }
    } catch (error) {
      console.error("accountAPI Error", error);
    } finally {
      setLoading(false);  // Set loading state to false once data is fetched
    }
  };

  // Fetch data when component is mounted or ID changes
  useEffect(() => {
    fetchData();
  }, [id]);

  // Fetch transactions and balance data for the selected month
  const fetchTxnByDate = async () => {
    const monthStartDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 0, 23, 59, 59, 999);
    const monthEndDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1);
    
    try {
      setLoading(true);  // Start loading state
      const results = await transactionAPI.getTransactionsByAccountIdWithMonth(id, selectedMonth);  // Fetch transactions
      const openBalance = await transactionAPI.sumTillDateByAccount(monthStartDate, id);  // Fetch opening balance
      const closeBalance = await transactionAPI.sumTillDateByAccount(monthEndDate, id);  // Fetch closing balance
      setTxns(results);  // Store transactions
      
      setOpenBalance(openBalance);  // Store opening balance
      setCloseBalance(closeBalance);  // Store closing balance
    } catch (error) {
      console.error("Error fetching transactions", error);
    } finally {
      setLoading(false);  // Set loading state to false once data is fetched
    }
  };

  // Trigger transaction fetch when account or month changes
  useEffect(() => {
    if (account) fetchTxnByDate();
  }, [id, account, selectedMonth]);

  // Handle month selection from the calendar view
  const handleNewDate = (newDate) => {
    // const newDate = new Date(year, month);
    setSelectedMonth(newDate);  // Update the selected month
  };

  // Handle report download based on user selection (Current Month, Lifetime, etc.)
  const handleDownload = (value) => {
    const endMonth = new Date();
    let startMonth = new Date();
    
    if (value === "Current Month") {
      startMonth.setDate(1);  // Start from the 1st of the current month
    } else if (value === "Lifetime") {
      startMonth = "lifetime";  // Set to 'lifetime' for lifetime report
    } else {
      const months = { "3 Months": 3, "6 Months": 6, "1 Year": 12 };
      startMonth.setMonth(endMonth.getMonth() - months[value]);  // Set start date based on selected range
      startMonth.setDate(startMonth.getDate() - 1);  // Adjust the start date for correct range
    }
    
    if (startMonth !== "lifetime") {
      setReportLoading(true);  // Show loader while generating the report
    }
    
    api.downloadMonthlyReport(id, startMonth, endMonth).then(() => {
      setReportLoading(false);  // Hide loader once the report is downloaded
    });
  };

  
  // View for displaying transactions (in and out) along with balances
  const TxnsView = () => (
    <div className="w-100 h-100">
      <div className="col-12 w-100 bg-app p-2 text-white d-flex justify-content-between">
        <h3 className=" d-flex">
          {/* Account profile picture */}
          {account?.get("photo") ? (
            <>
              {/* this image is used to handle broken link */}
              <img
                src={account.get("photo")}
                className="d-none"
                onError={(e) => {
                  setProfileUrl("https://placehold.co/150x150");
                }}
              />
              <div className="">
                <ModalImage
                  small={profileUrl || account.get("photo")}
                  large={profileUrl || account.get("photo")}
                  // showRotate={true}
                  hideZoom={true}
                  hideDownload={true}
                  className="profile-img"
                />
              </div>

            </>
          ) : (
            <i className="bi bi-person-circle mx-3"
              style={{ fontSize: "45px", color: "white" }}></i>
          )}

          {/* Account name */}
          <div className="my-auto fs-smaller ms-2">
            {account ? account.get("name").substring(0, 20) : ""}
          </div>
        </h3>
        {/* Month view component */}
        <MonthView onDateChange={handleNewDate} display={true} setAnimation={setAnimation} loading={loading} />
      </div>
      {/* </div> */}

      {/* Loading state or transaction view */}
     
        <div className={`background overflow-scroll h-100 w-100 ${animation}`}>
        {loading ? <Loader /> : (
          <div className="row w-100 justify-content-center">
            {/* Incoming transactions */}
            <div align="center" className="text-success col-6 min_h_35 ps-md-3 " style={{paddingBottom:"10vh"}} id="cashin">
            <div className="py-2  row">
                <i className="bi bi-arrow-down-circle-fill  fs-3 col-7 text-end"></i>
                <span className="fs-3 text-uppercase px-2 col-5 text-start"><b>In</b></span>
              </div>
              <OpenCloseBalance type="open" sum={openBalance} />
              <div className="list-group list-group-flush">
                {txns?.filter((txn) => txn.get("amount") > 0 && !txn.get("cancelled")).map((txn, i) => <Txn data={txn} key={i} />)}
              </div>
            </div>
            {/* <div className="col-1 text-center"><div className="vr h-100"></div></div> */}
            {/* Outgoing transactions */}
            <div align="center" className="text-danger col-6 min_h_35 ps-md-4" style={{paddingBottom:"10vh"}} id="cashout">
              <div className="py-2  row">
              <i className="bi bi-arrow-up-circle-fill fs-3 col-7 text-end"></i>
              <span className="fs-3 text-uppercase px-2 col-5 text-start"><b>Out</b></span>
              </div>
              <div className="list-group list-group-flush">
                {txns?.filter((txn) => txn.get("amount") < 0 && !txn.get("cancelled")).map((txn, i) => <Txn data={txn} key={i} />)}
                <OpenCloseBalance type="close" sum={closeBalance} />
              </div>
            </div>
          </div>
        )}
        </div>
    

      {/* Fixed bottom balance display */}
      <div className="fixed-bottom w-100 bg-app py-2 px-3 text-white d-flex">
        <h3 className="my-auto text-left text-md-left col-8" title="Account Balance">
          Balance : {account ? accounting.formatMoney(account.get("balance"), "₹", 0) : ""}
        </h3>
        {account && (
          <div className="ms-auto col-2 d-flex">
            <div className="col-6 ms-auto text-end w-100">
              <div className="dropdown">
                <button className="btn btn-app btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="d-flex align-items-center">
                    {reportLoading ?
                      <span className="spinner-border text-white" role="status" aria-hidden="true"></span> :
                      <i className="bi bi-file-earmark-arrow-down" style={{ fontSize: "2rem", color: "white" }} />}
                  </div>
                </button>
                {/* Dropdown menu for report options */}
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {["Current Month", "3 Months", "6 Months", "1 Year", "Lifetime"].map((item, idx) => (
                    <li key={idx}>
                      <button className="dropdown-item" onClick={() => handleDownload(item)}>{item}</button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  // View when no account is found
  const noAccountView = () => (
    <div class="background d-flex align-items-center justify-content-center vh-100">
        <div class="text-center">
            <h1 class="display-1 fw-bold">404</h1>
            <p class="fs-3"> <span class=" text-app">Oops!</span> Account not found.</p>
            <p class="lead">
                The Account you’re looking for doesn’t exist.
            </p>
            <Link to={"/"} class="btn btn-app">Go Home</Link>
        </div>
    </div>

  );

  // Render either transaction view or no account view based on data
  return (
    <>
      {account || loading ? TxnsView() : noAccountView()}
    </>
  );
};

export default PublicKhata;
