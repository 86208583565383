import accounting from "accounting";
import ReminderView from "./ReminderView";
import SubMenu from "./SubMenu";
import { useEffect, useState } from "react";
import useUniversalStore from "store/universalStore";
import { reminderAPI } from "services";

const Account = ({ account, isSelected, onSelect }) => {
  const [reminderAlert, setReminderAlert] = useState(null);
    const {isReminderChanged} = useUniversalStore((state)=>state);

    useEffect(() => {
        const fetchReminder = async () => {
            try {
                const alert = await reminderAPI.fetchRemainder(account.id)
                if(alert){
                    setReminderAlert(alert?.[0]);
                }
            } catch (error) {
                console.error("Error fetching reminder alert:", error);
                setLoading(false);
            }
        };

        fetchReminder();
    }, [account.id,isReminderChanged]);

    const nowTime = new Date().getTime();
    const remindBool =  (nowTime > reminderAlert?.get("date")?.getTime());
  return (
    <li role="button" className={`list-group-item ${ isSelected ? "bg-danger bg-opacity-25" : "bg-transparent border-none"}`}
      onClick={onSelect}
    >
      <div className="row fs-5">
        <div className="col-md-11 py-2 row pe-auto">
          <div className={`col-md-9 ${ account.get("cancelled")
                ? " text-muted text-decoration-line-through"
                : "fw-bold"}`}
          >
            <ReminderView account={account} remindBool={remindBool} />
            {account.get("name").length > 50
              ? account.get("name").substring(0, 50) + " ..."
              : account.get("name")}
          </div>
          <div className="col-md-3">
            <span className={`fw-bold ${account.get("balance") < 0 ? "text-danger" : "text-success"}
              ${ account.get("cancelled") ? "text-muted text-decoration-line-through" : "" }`}>
              {accounting.formatMoney(Math.abs(account.get("balance")), "₹", 0)}
            </span>
          </div>
        </div>
        <div className="col-md-1"><SubMenu account={account} remindBool={remindBool} /></div>
      </div>
    </li>
  );
};

export default Account;