import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { accountAPI, api, transactionAPI } from "services";
import ModalImage from "react-modal-image";
import { FaFilePdf } from "react-icons/fa";
import { ConfirmationModal } from "components";
import { NewTxn, EditTxn, EditRTxn } from "views/forms";
import useUniversalStore from "store/universalStore";
import { formatMoney } from "utils";

const Txn = ({ data, currentDate, setLoading }) => {
  const {accountId, transactionId, write, accountDetails} = useUniversalStore((state) => state);
  const isPDF = data.get("attachment")?.includes(".pdf");
  const isImage = data.get("attachment")?.match(/\.(jpg|jpeg|png|gif|webp)$/i);
  const navigate = useNavigate()

  const handleModalOpen = () => {
    const modal = new window.bootstrap.Modal(document.getElementById('deleteTxnInCashbookModal'));
    modal.show();
  };

  const fetchTransactions = async () => {
    try {
      const results = await transactionAPI.getTransactionsByAccountIdWithMonth(accountId,currentDate);
      write({ transactions: results });
    } catch (error) {
      console.error(error);
    }
  };

  //method to navigate home page and auto select the khatha account
  const handleNavigate = async () => {
    const accountId = data?.get("accountId")?.id;
    const date = data?.get("date");
    if(!accountId) return;
    await fetchTransactions(accountId);
    write({ accountId: accountId , accountDetails : accountDetails ? accountDetails : await accountAPI.getKathaAccountById(accountId)});
    navigate("/",{state:date});
  };

  const deletetransaction = async (transactionId) => {
    try{
      setLoading(true)
        await transactionAPI.deleteTransaction(transactionId);
        //Reset the transactionId
        write({ 
          transactionId : null,
          transactions: await transactionAPI.getTransactionsByDate(currentDate)
        });
      } catch (err) {
        console.log(err);
      }finally{
        setLoading(false)
      }
  }

  const cancelledView = () => {
    return (
      <div className="row align-baseline">
        <div className="col-3" align="right">
          <i className="text-decoration-line-through text-muted">
            {formatMoney(data.get("amount"))}
          </i>
        </div>
        <div className="col-9" onClick={handleNavigate}>
          {data.get("accountId") ? (
            <div className="row">
              <div className="col text-start">
                <a className="text-decoration-line-through text-muted pe-none">
                  {data.get("accountId")?.get("name")}
                </a>
              </div>
            </div>
          ) : null}
          <div className="row text-start">
            <div className="col text-decoration-line-through text-muted">
              {data.get("detail")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const normalView = () => {
    return (
      <div className="row align-baseline" >
        <div className="col-3" align="right">
          <i className="text-decoration-none text-color-format">
            {Math.abs(data.get("amount")).toLocaleString("en-IN", {
              maximumFractionDigits: 0,
              style: "currency",
              currency: "INR",
            })}
          </i>
        </div>
        <div className="col-8" onClick={handleNavigate}>
          {data.get("accountId") ? (
            <div className="row">
              <div className="col text-start">
                <a href="#"
                  // Need to open khata view if clicked on account name
                  className="text-decoration-none pe-auto fw-bold fst-italic text-dark">
                  {data.get("accountId")?.get("name")}
                </a>
              </div>
            </div>
          ) : null}
          <div className="row text-start">
            <div className="col">{data.get("detail")}</div>
          </div>
          <div className="row text-start">
            {isPDF && (
              <a role="button"
                href={data.get("attachment")}
                target="_blank"
                style={{ cursor: "pointer" }}>
                <FaFilePdf
                  size={50}
                  className="text-app"
                />
              </a>
            )}

            {isImage && (
              <ModalImage
                small={data.get("attachment")}
                large={data.get("attachment")}
                hideDownload={false}
                hideZoom={false}
                className="col-8 rounded-3"
              />
            )}
          </div>
        </div>
        <div className="col-1">
          <a
            className="text-black btn bg-not-transparent-on-hover w-auto"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            title="More options"
            align="right"
          >
            <span className="bi bi-three-dots-vertical"></span>
          </a>
          <ul className="dropdown-menu row w-auto">
            <li className="col-12 py-1">
              <button
                className="w-100 btn btn-outline-app rounded"
                data-bs-toggle="offcanvas"
                data-bs-target="#editTxnDrawer"
                onClick={() => write({ transactionId : data.id })}
                >
                <span className="bi bi-pencil-fill"> Edit</span>
              </button>
            </li>
            <li className="col-12 py-1">
              <button
                className="w-100 btn btn-app rounded"
                // data-bs-toggle="modal"
                // onClick={() => {
                //   write({ transactionId : data.id });
                // }}
                // data-bs-target="#deleteTxnInCashbookModal"
                onClick={()=>{
                  write({ transactionId : data.id })
                  handleModalOpen()
                }}
                >
                <span className="bi bi-trash"> Delete</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className="list-group-item bg-transparent cursor">
      {data.get("cancelled") ? cancelledView() : normalView()}
      <ConfirmationModal id="deleteTxnInCashbookModal" onClick={deletetransaction} />
    </div>
  );
}

export default Txn;
