import { useEffect, useState } from "react";
import { reminderAPI } from "services";
import { Status } from "components";
import { TimePicker } from "antd";
import useUniversalStore from "store/universalStore";
import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";
import moment from "moment";

const Reminder = () => {
  const [remainder, setRemainder] = useState(null);
  const [edit, setEdit] = useState(false);
  const [sheduleReminder, setSheduleReminder] = useState(getMinDate());
  const [values, setValues] = useState({
    status: { type: null, message: "" },
    loading: false,
  });
  const { accountId, write, isReminderChanged } = useUniversalStore((state) => state);
  const enabled = remainder?.get("enabled");
  const reminderDateTime = remainder?.get("date");
  const currentDateTime = dayjs(sheduleReminder);
  const today = dayjs(); // Get the current date and time

  // overdue is true if remainder date and time less then current date and time
  const overDue =   new Date().getTime() > reminderDateTime?.getTime();
  const handleClose = () => setRemainder(null);

  //intially fetch the Remainder details
  useEffect(() => {
    fetchRemainder();
  }, [accountId]);

  //method to return minDate and it should be atleast 15 min ahed of current time
  function getMinDate() {
    const minDate = new Date();
    minDate.setDate(minDate.getDate());
    minDate.setHours(
      minDate.getHours(),
      minDate.getMinutes() + 15,
      minDate.getSeconds(),
      minDate.getMilliseconds()
    ); // Keep the current time of the day
    return minDate;
  }

  //method to fetch Remainder and add results in global store
  const fetchRemainder = async () => {
    try {
      handleLoading(true)
      const response = await reminderAPI.fetchRemainder(accountId);
      setRemainder(response[0]);
      setSheduleReminder(response[0]?.get("date") || getMinDate());
    } catch (error) {
      console.error("fetchRemainder error ", error);
    }finally{
      handleLoading(false)
    }
  };

  //handle shedule reminder
  const handleChangeDate = (e) => {
    const newDate = new Date(e);
    const currentDate = new Date();
    if (newDate < currentDate) return;
    setSheduleReminder(newDate); // only return future dates
  };

  //method to shedule remainder
  const handleShedule = async (e) => {
    e.preventDefault();
    try {
      handleLoading(true)
      const reminder = await reminderAPI.addReminder( accountId, sheduleReminder, true );
      if (reminder) {
        setRemainder(reminder);
        success("Reminder scheduled successfully!");
      }
    } catch (error) {
      console.error("handleShedule error: ", error.message);
      failure("Failed to schedule Reminder!");
    }
  };

  //method to toggle Reminder
  const handleReminderToggle = async () => {
    try {
      handleLoading(true)
      const res = await reminderAPI.toggleReminder(remainder?.id);
      if(res){
        write({isReminderChanged:false})
        setRemainder(res);
      }
    } catch (error) {
      console.error("handleReminderToggle error: ", error);
    }finally{
      handleLoading(false)
    }
  };

  //method to delete the reminder
  const handleDeleteRemainder = async () => {
    try {
      const response = await reminderAPI.deleteRemainder(remainder?.id);
      if (response) {
        write({isReminderChanged:!isReminderChanged})
        success("Reminder has been removed successfully!");
        setRemainder(null);
        setSheduleReminder(getMinDate());
        //TODO: Update global store.
      }
    } catch (error) {
      failure("Failed to delete the reminder!")
      console.error("handleDeleteRemainder error: ", error);
    }finally{
      handleLoading(false)
    }
  };

  //method to enable the loader
  const handleLoading = (state) => {
    setValues((prev)=>({...prev, loading:state}))
  };

  //method to set the failure status
  const failure = (msg) => {
    setValues({ status: { type: "error", message: msg } });
    setTimeout(() => {
      setValues({ status: { type: null, message: "" }, loading: false });
    }, 2500);
  };

  //method to set the success status
  const success = (msg) => {
    setValues({ status: { type: "success", message: msg } });
    setTimeout(() => {
      setValues({ status: { type: null, message: "" }, loading: false });
    }, 2500);
  };

  // Handle time change
  const handleTimeChange = (e) => setSheduleReminder(new Date(e));

  //shedule overdue reminder section
  const sheduleOverDueReminder = () => {
    if (!reminderDateTime) {
      return (
        <div className="animate__animated animate__fadeIn">
          <div className="mb-1">
            <label className="display-6 py-2 text-black">
              When do you want to be Reminded?
            </label>
            <div className="d-flex py-3 w-100 justify-content-end">
              {/* <div className="d-flex w-100"> */}
              <DateTimePicker
                // className="w-100"
                calendarIcon={null}
                clearIcon={null}
                minDate={getMinDate()}
                disableClock={true}
                format="dd-MM-y"
                onChange={handleChangeDate}
                value={sheduleReminder}
              />
              <TimePicker
                // className="w-100"
                format="hh:mm A"
                value={dayjs(sheduleReminder)}
                onChange={handleTimeChange}
                changeOnScroll={false}
                needConfirm={true}
                allowClear={false}
                use12Hours={true}
                showNow={false}
                suffixIcon={null}
                disabledHours={() => {
                  const hours = [];
                  const selectedDate = dayjs(sheduleReminder); // get the scheduled select date
                  const isToday = selectedDate.isSame(today, 'day'); //check scheduled date is today
                  if(isToday){
                    for (let i = 0; i < currentDateTime.hour(); i++) {
                      hours.push(i);
                    }
                  }
                  return hours;
                }}
                disabledMinutes={(hour) => {
                  const minutes = [];
                  const selectedDate = dayjs(sheduleReminder); // get the scheduled select date
                  const isToday = selectedDate.isSame(today, 'day'); //check scheduled date is today
                  if(isToday && hour === currentDateTime.hour()){
                    for (let i = 0; i < currentDateTime.minute(); i++) {
                      minutes.push(i);
                    }
                  }
                  return minutes;
                }}
              />
              {/* </div> */}
              
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-6 d-grid mt-md-0">
              <button
                type="cancel"
                class="w-100 btn btn-outline-app"
                data-bs-dismiss="offcanvas"
                onClick={() => (edit ? setEdit(false) : handleClose())}
              >
                <b className="fs-5">Cancel</b>
              </button>
            </div>
            <div className="col-md-6 d-grid mt-md-0">
              <button
                type="submit"
                className="btn btn-app block py-3"
                data-bs-dismiss="offcanvas"
                onClick={handleShedule}
              >
                <b className="fs-5">Schedule</b>
              </button>
            </div>
          </div>
          <br />
        </div>
      );
    }

    return (
      <small className="text-muted mb-2">
        <div className="alert">
          <h4 className="display-6 text-center text-dark">Current Reminder</h4>
          {overDue ? (
            <div className="text-danger text-center h6 my-2">
              <i className="bi bi-patch-exclamation"></i> Payment Overdue
            </div>
          ) : null}
        </div>
        <div className="row">
          <ul className="list-group list-group-horizontal col-12 flex-wrap my-2 p-0">
            <li className={ overDue ? "list-group-item col-8" : "list-group-item col-8" }>
              <h3 className={enabled ? "text-danger text-center" : "text-center"}>
                { sheduleReminder ? (
                  <>
                    <i className="bi bi-calendar"></i>
                    <i className="px-2">{moment(sheduleReminder).format("DD-MM-YYYY")}</i>
                    <br />
                    <i className="px-4">{sheduleReminder.toLocaleTimeString(undefined, {timeStyle: "short"})}</i>
                  </>
                ) : ( "N/A" )}
              </h3>
            </li>
            <li className="list-group-item col-2 d-flex">
              <i
                className={`bi bi-toggle2-${ enabled ? "on" : "off" } my-auto fs-3`}
                // data-bs-dismiss="offcanvas"
                data-bs-dismiss = {overDue ? "offcanvas" : ""}
                onClick={handleReminderToggle}
                type="button"
                title="Disable Reminder"
              ></i>
            </li>
            <li className="list-group-item col-2 d-flex">
              <i
                className="bi bi-trash my-auto fs-3"
                data-bs-dismiss="offcanvas"
                onClick={handleDeleteRemainder}
                type="button"
                title="Delete Reminder"
              ></i>
            </li>
          </ul>
        </div>
      </small>
    );
  };

  //off canvas modal
  const canves = () => {
    return (
      <>
        <div
          className="btn btn-sm bg-app rounded"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRightReminder"
          aria-controls="offcanvasRightReminder"
          title="Set reminder Date"
        >
          <div className="position-relative">
            {/* <i class="bi bi-alarm-fill" style={{ fontSize: "2rem", color: "white" }}></i> */}
            {remainder?.get("date") ? (
              <>
               <i class="bi bi-alarm-fill" style={{ fontSize: "2rem", color: "white" }}></i>
               {/* <span class="position-absolute translate-middle">
                <i className="bi bi-patch-exclamation-fill text-white me-2 fs-4" title="Payment Due!"></i>
              </span> */}
              </>
            ):<i className="bi bi-alarm" style={{ fontSize: "2rem", color: "white" }}></i>}
          </div>
        </div>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasRightReminder"
          aria-labelledby="offcanvasRightReminderLabel"
        >
          <div className="offcanvas-header bg-app text-light text-center">
            <i
              className="bi bi-alarm"
              aria-hidden="true"
              style={{ fontSize: "2rem" }}
            ></i>
            <h3 className="offcanvas-title" id="offcanvasRightReminderLabel">
              <span className="px-3">Set Reminder</span>
            </h3>
            <button
              type="button"
              class="btn-close bg-light"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">{sheduleOverDueReminder()}</div>
        </div>
      </>
    );
  };
  
  return (
    <>
      {canves()}
      <div className="status-center" style={{ zIndex: 1, top: -100 }}>
        <Status type={values.status?.type} message={values.status?.message} />
      </div>
    </>
  );
}

export default Reminder;