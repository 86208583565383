import React, { useEffect, useState } from "react";
import RecursiveTxns from "./Recursive/RecursiveTxns";
import OneTimeTxn from "./OneTimeTxn";
import { useLocation } from "react-router-dom";
import { hideOffCanvas } from "utils";
import { Loading, Status } from "components";
import useUniversalStore from "store/universalStore";

function NewTxn(props) {
  const { handleChangeMonth } = props;
  const [txnType, setTxnType] = useState("onetime");
  const [status,setStatus] = useState({type:null,message:""})
  const LOCATION_PATHNAME = window.location.pathname;

  useEffect(() => {
    // document.getElementById("addTxnDrawer")
    //   ?.addEventListener("hide.bs.offcanvas", (e) => {
    //     setTxnType(() => "onetime");
    //   });
  }, []);

  /*
  <div className="offcanvas offcanvas-start text-danger"
      tabIndex="-1"
      id="addAccountDrawer">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Add Account</h3>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body container-fluid">
        <div className="row">
          <div className="col-12">
          */

  return (
    <>
      <div
        className="offcanvas offcanvas-end text-danger"
        tabIndex="-1"
        id="addTxnDrawer"
      >
        <div className="offcanvas-header bg-app text-light">
          <h3 className="offcanvas-title">Add Transaction</h3>
          <button
            type="button"
            className="btn-close text-reset bg-light"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body container-fluid ">
          <div className="row bg-white mb-5">
            <div className="col-12"></div>
            <div className="btn-group w-100 py-3" role="group">
              <button
                type="button"
                className="btn btn-app btn-block"
                onClick={(e) => setTxnType("onetime")}
              >
                One Time
              </button>
              <button
                type="button"
                className="btn btn-outline-app btn-block"
                onClick={(e) => setTxnType("recurring")}
              >
                Recurring
              </button>
            </div>
            <OneTimeTxn setStatus={setStatus} handleChangeMonth={handleChangeMonth} />
            {/* {txnType === "onetime" ? <OneTimeTxn /> : <RecursiveTxns /> } */}
          </div>
        </div>
      </div>
      <div className="row">
      {/* status */}
        {(LOCATION_PATHNAME === "/cashbook" && status.type === "success") ? (
        <div className="position-fixed w-auto" style={{ zIndex: 2, top: "60%", left: "50%",transform:"translateX(-50%)" }}>
          <div className="rounded-circle position-relative" style={{width:"100px",height:"100px", backgroundColor:"#32CD32", boxShadow: "0 1rem 3rem #000" }}>
            <i className="bi bi-check2 text-white position-absolute" style={{ fontSize: "50px", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}></i>
          </div>
        </div>
      ) : (
        status.type === "success" && (
        <div className="status-center" style={{top:"30%"}}>
          <div className="rounded-circle position-relative" style={{width:"100px",height:"100px", backgroundColor:"#32CD32", boxShadow: "0 1rem 40px #000" }}>
            <i className="bi bi-check2 text-white position-absolute" style={{ fontSize: "50px", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}></i>
          </div>
        </div>
        )
      )}
      </div>
    </>
  );
}
export default NewTxn;