import { auth, accountAPI } from "services";
import Parse from "./parse.service";
const Reminder = Parse.Object.extend("Reminder");

const user = auth.fetchUser();

export const addReminder = async (accountId, date, isEnabled) => {
  const reminder = new Reminder();
  const account = await accountAPI.getAccountById(accountId);
  reminder.set("accountId", account);
  reminder.set("userId", user);
  reminder.set("date", date);
  reminder.set("enabled", isEnabled);
  return await reminder.save();
};

//toggle the enabled column based on remainder Id
export const toggleReminder = async (reminderId) => {
  const query = new Parse.Query(Reminder);
  // query.equalTo("objectId", id);
  // const reminder = await query.get(id);
  const reminder = await query.get(reminderId)
  reminder.set("enabled", !reminder?.get("enabled"));
  return await reminder.save();
};

export const getReminderAlert = async (id) => {
  const currentdate = new Date().toLocaleString("en-US", {
    hours12: true,
    day: "2-digit",
  });
  //const reminder = await api.getReminderById(id);
  const myAccount = await accountAPI.getAccountById(id);
  const serverDate = myAccount.get("date");
  if (serverDate == currentdate) {
    return { status: "success" };
  } else {
    return { status: "error" };
  }
};

//fetch the remainder data of current user
export const fetchRemainder = async (accountId) => {
  try {
    const currentUser = auth.fetchUser();

    //creating account pointer
    const Account = Parse.Object.extend("Account");
    const accountPointer = new Account();
    accountPointer.id = accountId;

    const query = new Parse.Query(Reminder);
    query.equalTo("userId", currentUser); //user pointer
    query.equalTo("accountId", accountPointer); //account pointer

    const remainder = await query.find();
    return remainder;
  } catch (error) {
    console.error("fetchRemainder Error: ", error);
  }
};

export const fetchAllReminder = async(accountId)=>{
  try {
    const query = new Parse.Query(Reminder);
    query.include("accountId",accountId);
    const response = await query.find();
    return response;
    
  } catch (error) {
    console.error("fetchAllReminder error: ",error)
  }
}
export const deleteRemainder = async (remainderId) => {
  try {
    const query = new Parse.Query(Reminder);
    query.equalTo("objectId", remainderId);
    query.equalTo("userId", auth.fetchUser());
    const remainder = await query.get(remainderId);
    const response = await remainder.destroy();
    return response
  } catch (error) {
    console.error("deleteRemainder error: ", response);
  }
};