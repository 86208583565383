import React from "react";

const OpenCloseBalance = ({type, sum}) => {
  return(
<div className="d-flex flex-wrap align-items-center justify-content-center w-100 text-dark ">
  <div className="w-25">
    <div className="fs-amount text-end fw-bold">
      <i className="col" style={{ whiteSpace: 'nowrap' }}>{sum.toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        style: 'currency',
        currency: 'INR'
      })}</i>
    </div>
  </div>
  <div className="flex-grow-1 text-start ps-4">
    <b className="fs-xmd-smaller fs-5">
      {type == "open" ? "Open Balance" : "Close Balance"}
    </b>
  </div>
</div>

  )
}

export default OpenCloseBalance;
