import React, { useEffect, useState } from "react";
import { accountAPI, api } from "services";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toggleOffCanvas } from "utils";
import { Status, ProfilePhoto, Loader } from "components";
import useUniversalStore from "store/universalStore";
import {PhoneInput} from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { auth } from "services";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const EditAccount = () => {
  //TODO : Merge this with AddAccount
  //TODO : Currently address, detail, phone no. are coming garbage state if nothing present.
  //TODO : Show progress when image uploading

  const accountId = useUniversalStore((state) => state.accountId);
  const write = useUniversalStore((state) => state.write);
  
  const initialState = {
    name: "",
    phone: "",
    address: "",
    markup: "",
    photo: { url: null, loading: false ,file:null},
    loading: true,
    isUpdating:false,
    status: { type: null, message: "" },
  };

  const [state, setState] = useState(initialState);
  const isValidNumber = isPhoneValid(state.phone);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const updateField = (field, value) => {
    setState((prev) => ({ ...prev, [field]: value }));
  }
  
  const fetchAccountData = async () => {    
    const account = await accountAPI.getAccountById(accountId);
    if(account){
      setState((prev) => ({
        ...prev,
        loading: false,
        name: account?.get("name"),
        phone: account?.get("phone"),
        address: account?.get("address"),
        markup: account?.get("markup"),
        photo: { url: account?.get("photo") || null, loading: false},
        status: { type: null, message: "" },
      }));
    }
  }

  // Reset form values when offcanvas is closed
  useEffect(() => {
    const offCanvas = document.getElementById("editAccountDrawer");
    if(!offCanvas) return;

    const handleHidden = () => {
      setState(initialState);
    };

    const handleShown = () => {
      fetchAccountData();
    };

    offCanvas.addEventListener("hidden.bs.offcanvas", handleHidden);
    offCanvas.addEventListener("shown.bs.offcanvas", handleShown);

    return () => {
      //remove event listeners when offcanvas is hidden
      offCanvas.removeEventListener("hidden.bs.offcanvas", handleHidden);
      offCanvas.removeEventListener("shown.bs.offcanvas", handleShown);
    };
  },[accountId]);



  function offErrorAfterInterval(){
    setTimeout(() => {
      setIsSuccessMessageVisible(false);
      setState((prev)=>({
        ...prev, 
        status: { type: null, message: "" },
        loading:false,
        isUpdating:false,
        photo: { loading: false, file: prev.photo.file, url: prev.photo.url }
      }));
    }, 2000);
  }

  const updateAccount = async (e) => {
    e.preventDefault();
    setState((prev) => ({ 
      ...prev,
      isUpdating:true,
    }));
    
    try {
      // Check if name is provided
      if (!state.name) {
        setState((prev) => ({
          ...prev,
          loading: false,
          photo: { ...prev.photo,loading: false },
          isUpdating:false,
          status: { type: "error", message: "Name is required" },
        }));
        offErrorAfterInterval();
        return;
      }

      if (!isValidNumber && state.phone) {
        setState((prev) => ({
          ...prev,
          loading: false,
          isUpdating:false,
          status: { type: "error", message: "Invalid phone number" },
        }));
        offErrorAfterInterval();
        return;
      }


      // Check if name exceeds 50 characters
      if (state.name.length > 50) {
        setState((prev) => ({
          ...prev,
          loading: false,
          photo: { ...prev.photo,loading: false },
          isUpdating:false,
          status: { type: "error", message: "Name limit exceeded (max 50 characters)" },
        }));
        offErrorAfterInterval();
        return;
      }

      

      const account = await accountAPI.getAccountById(accountId);
      const accountOldname = account?.get("name");

      if(accountOldname!==state.name){
        // Check if an account already exists by phone number
        const accountByName = await accountAPI.getAccountByName(state.name);
        if (accountByName) {
          setState((prev) => ({
            ...prev,
            loading: false,
            isUpdating:false,
            photo:{loading:false,url:prev.photo.url,file:prev.photo.file},
            status: { type: "error", message: "Account with this name already exists." },
          }));
          offErrorAfterInterval();
          return;
        }
      }


      //Update selected account data
      const updatedAccount = await accountAPI.updateAccount(
        accountId,state.name,state.phone,state.address,
        state.markup,state.photo.url);
      //Update accounts in global store
      console.log(updatedAccount);
      
      write({
        accounts: await accountAPI.getAccounts(),
        accountId: accountId,
        accountDetails:updatedAccount,
      });

      setState((prev) => ({ ...prev, 
        loading: false,
        status: { 
          type:"success", message: "Account Updated Successfully!" 
        },
        isUpdating:false,
      }));
      
      toggleOffCanvas("editAccountDrawer");
      setIsSuccessMessageVisible(true);
      offErrorAfterInterval();      
    } catch (error) {
      setState((prev) => ({
        ...prev,
        status: { type: "error", message: error.message },
        isUpdating:false,
      }));
    }
  };


  return (
    <>
    <div className="offcanvas offcanvas-end text-danger"
      tabIndex="-1"
      id="editAccountDrawer">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Account Details</h3>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body container-fluid">
          <div className="row">
            <div className="col-12">
              {state.loading ? <Loader /> :
                <form onSubmit={updateAccount}>
                  <div className="my-3" align="center">
                    <ProfilePhoto
                      key="edit-profile-photo"
                      url={state.photo.url}
                      loading={state.photo.loading}
                      updateField={(field,data)=>setState(prev=>({...prev,[field]:data}))}
                      />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className={`form-control`}
                      placeholder="Account Name"
                      value={state.name}
                      onChange={(e) => updateField("name", e.target.value)}
                      required />
                  </div>
                  <div className="mb-3">
                    {/* <input
                      type="number"
                      className={`form-control ${
                        invalidPhone ? "is-invalid" : state.phone ? "is-valid" : ""
                      }`}
                      placeholder="Phone no."
                      value={state.phone}
                      onChange={(e) => updateField("phone", e.target.value)} /> */}
                  <PhoneInput
                    defaultCountry="in"
                    className={`form-control ${!isValidNumber && state.phone ? "is-invalid" : state.phone ? "is-valid" : ""}`}
                    value={state.phone}
                    hideDropdown={true}
                    onChange={(phone) => updateField("phone", phone)}
                    placeholder="Phone no."
                    style={{ width: "100%" }}
                  />
                  {!isValidNumber && <div className="invalid-feedback">Please enter a valid phone number.</div>}
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className={`form-control ${state.address ? "is-valid" : ""}`}
                      placeholder="Address"
                      value={state.address}
                      onChange={(e) => updateField("address", e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <textarea
                      type="text"
                      className={`form-control ${state.markup ? "is-valid" : ""}`}
                      placeholder="Other Details"
                      value={state.markup}
                      onChange={(e) => updateField("markup", e.target.value)} />
                  </div>
                  {state.status.type === "error" && <Status type={state.status.type} message={state.status.message} />}
                  <div className="row my-3 sticky-bottom-drawer">
                    <div className="col-md-12 d-grid">
                      <button
                        type="submit"
                        className="btn btn-app py-3 fs-5 fw-bold text-uppercase"
                        disabled={state.isUpdating || state.photo.loading || !state.name}>
                        {state.photo.loading ? <span className="spinner-border spinner-border-sm me-2"></span> :"Update"}
                      </button>
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
      </div>
    </div>
    {isSuccessMessageVisible  && <div style={{
      "position": "absolute",
      "top": "50%",
      "left": "50%",
      "transform": "translate(81%, 390%)",
      "zIndex": "9999",  /* Ensure it's on top of other elements */
      "display": "flex",
      "justifyContent": "center",
      "alignItems": "center"
    }}>
     <Status type={"success"} message={"Account Updated successfully!"} />
    </div>}
    </>
  );
};

export default EditAccount;
