import React, { useCallback, useEffect, useMemo, useState } from "react";
import { accountAPI, api } from "services";
import "react-phone-number-input/style.css";
import {PhoneInput} from 'react-international-phone';
import 'react-international-phone/style.css';
import { Status, ProfilePhoto } from "components";
import useUniversalStore from "store/universalStore";
import Creatable from "react-select/creatable";
import { creatableStyle } from "assets/themes";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { toggleOffCanvas } from "utils";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const NewAccount = () => {
  // TODO : Accounts list refresh after adding a new account.
  // TODO : New account not getting added.
  // TODO : Trying to Add new account .... adds same as phone number too...
  // TODO : Profile Photo not being added...
  // TODO : New account created doesn't show up Close button.
  // TODO : Allow adding new account without profile photo.

  const [allContacts, setAllContacts] = useState([]);
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [reRenderProfile,setReRenderProfile] = useState(false);
  const initialState = {
    name: "",
    phone: { value: "", error: "" },
    address: "",
    detail: "",
    photo: { url: null, loading: false, file: null },
    loading: false,
    status: { type: null, message: "" },
  };
  const [values, setValues] = useState(initialState);
  const write = useUniversalStore((state) => state.write);
  const isValidNumber = isPhoneValid(values.phone.value);
  

  // Fetch contacts from API and store them in state
  const fetchContacts = async () => {
    setIsFetching(true);
    try {
      const res = await api.getContacts();
      if (res && res[0]) {
        const contacts = res[0].get("phonebook") || [];
        setAllContacts(contacts);
      }
    } catch (error) {
      console.error("Failed to fetch contacts", error);
    } finally {
      setIsFetching(false);
    }
  };

  // Fetch contacts once on component mount
  useEffect(() => {
    fetchContacts();
  }, []);

  // Map fetched contacts into usable options for select input
  const contactOptions = useMemo(() => {
    return allContacts.filter(contact => contact?.name)  // Filter out contacts without a name
    .sort((a, b) => a.name.localeCompare(b.name))  // Sort by name alphabetically
    .map((contact) => ({
      value: contact?.number?.replace(/\s+/g, ""), // Remove any spaces from the phone number
      label: contact?.name,
    })); ;
  }, [allContacts]);

  useEffect(() => {
    setOptions(contactOptions);
  }, [contactOptions]);

   // Reset form values when offcanvas is closed
   useEffect(() => {
    const offCanvas = document.getElementById("addAccountDrawer");
    if (!offCanvas) return;

    const handleHidden = () => {
      setValues(initialState);
      setReRenderProfile(false);
    };
    const handleShown = () => {
      setValues(initialState);
      setReRenderProfile(true);
    };

    offCanvas.addEventListener("hidden.bs.offcanvas", handleHidden);
    offCanvas.addEventListener("shown.bs.offcanvas", handleShown);

    return () => {
      //remove event listeners when offcanvas is hidden
      offCanvas.removeEventListener("hidden.bs.offcanvas", handleHidden);
      offCanvas.removeEventListener("shown.bs.offcanvas", handleShown);
    };
  }, []);

  // Remove country code (e.g., +91) from phone number
  function removeCountryCode(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '').replace(".", "");
    return cleanedPhoneNumber.slice(-10);
  }

  function offErrorAfterInterval(){
    setTimeout(() => {
      setIsSuccessMessageVisible(false);
      setValues((prev)=>({
        ...prev, 
        status: { type: null, message: "" },
        loading:false,
        isUpdating:false,
        photo: { loading: false, file: prev.photo.file, url: prev.photo.url }
      }));
    }, 2000);
  }


  // Handle the account creation process
  const addAccount = async (e) => {
    e.preventDefault();
    setValues((prev) => ({...prev, loading: true }));
    try {
      
      if (!values.name) {
        setValues((prev) => ({
          ...prev,
          loading: false,
          status: { type: "error", message: "Name is required" },
          photo: { ...prev.photo, loading: false }
        }));
        offErrorAfterInterval();
        return;
      }

      if (!isValidNumber && values.phone.value) {
        setValues((prev) => ({
          ...prev,
          loading: false,
          status: { type: "error", message: "Invalid phone number" },
        }));
        offErrorAfterInterval();
        return;
      }

      if (values.name.length > 50) {
        setValues((prev) => ({
          ...prev,
          loading: false,
          photo: { ...prev.photo, loading: false },
          status: { type: "error", message: "Name limit exceeded (max 50 characters)" },
        }));
        offErrorAfterInterval();
        return;
      }

      const accountByName = await accountAPI.getAccountByName(values.name.trimEnd());
      if (accountByName) {
        setValues((prev) => ({
          ...prev,
          loading: false,
          status: { type: "error", message: "Account with this name already exists." },
          photo: { ...prev.photo, loading: false }
        }));
        offErrorAfterInterval();
        return;
      }

      // Creating the account using the API  
      const result = await accountAPI.createAccount(
        values.name,
        values.phone.value,
        values.address,
        values.detail,
        values.photo.url
      );
      
      // Refresh data in global state
      write({ accounts: await accountAPI.getAccounts() ,accountId:result.id ,accountDetails:result});

      // Set success message after account creation
      setValues((prev) => ({
        ...prev,
        status: { type: "success", message: "Account created successfully!" },
        loading: false,
        photo: { loading: false, url: prev.photo.url, file: prev.photo.file }
      }));

      // Reset form and close offcanvas
      setTimeout(() => {
        toggleOffCanvas("addAccountDrawer");
        setValues((prev) => ({
          ...prev,
          loading: false
        }));
        setIsSuccessMessageVisible(true);
        offErrorAfterInterval();
      }, 100);


    } catch (error) {
      setValues((prev) => ({
        ...prev,
        loading: false,
        status: { type: "error", message: error.message || "Failed to create account." },
      }));
      offErrorAfterInterval();
    }
  };

  // Update the form field values
  const handleField = (field, value) => {
    if (field === "phone") {
      setValues((prev) => ({ ...prev, "phone": { value: value.value, error: "" } }));
      return;
    }
    setValues((prev) => ({ ...prev, [field]: value }));
  };

  // Handle the change when selecting contacts from the dropdown
  const accountChangeHandler = (selectedOption) => {
    if (!selectedOption || !selectedOption.label) {
      setValues((prev) => ({
        ...prev,
        name: "",
        phone: { value: "", error: "" },
      }));
      return;
    }

    if (selectedOption.__isNew__) {
      setValues((prev) => ({
        ...prev,
        name: selectedOption.label,
        phone: { value: prev.phone.value, error: "" },
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        name: selectedOption.label,
        phone: { value: selectedOption.value || "", error: "" },
      }));
    }
  };

  // Render the Creatable component for selecting contacts
  const selectContacts = useCallback(() => (
    <Creatable
      options={options}
      value={values.name ? { label: values.name, value: values.phone.value } : null}
      classNamePrefix="rs"
      isLoading={isFetching}
      isClearable
      placeholder="Select Party Name from Contact"
      onChange={accountChangeHandler}
      styles={creatableStyle}
    />
  ), [options, values.name, values.phone.value, accountChangeHandler]);

  return (<>
    <div className="offcanvas offcanvas-start text-danger" tabIndex="-1" id="addAccountDrawer">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Add Account</h3>
        <button type="button" className="btn-close text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body container-fluid">
        <form onSubmit={addAccount} >
          <div className="my-4 placeholder-glow" align="center">
            {reRenderProfile ? <ProfilePhoto
              key="add-profile-photo"
              url={values.photo.url}
              loading={values.photo.loading}
              updateField={(field, data) => setValues(prev => ({ ...prev, [field]: data }))} /> :
              <div style={{
                width: "150px",
                height: "150px"
              }} className="my-3 placeholder rounded-circle border border-1 border-danger">
                <img
                  src={"https://placehold.co/150x150"}
                  className="img-fluid rounded-circle border border-1"
                />
              </div>}
          </div>
          <div className="mb-3">{selectContacts()}</div>
          <div className="mb-3">
            <PhoneInput
              defaultCountry="in"
              hideDropdown={true}
              className={`form-control ${!isValidNumber && values.phone.value ? "is-invalid" : values.phone.value ? "is-valid" : ""}`}
              value={values.phone.value}
              onChange={(phone) => handleField("phone", { value: phone, error: "" })}
              placeholder="Phone no."
              style={{ width: "100%" }}
            />
            {!isValidNumber && <div className="invalid-feedback">Please enter a valid phone number.</div>}
          </div>
          <div className="mb-3">
            <input
              type="text"
              className={`form-control ${values.address ? "is-valid" : ""}`}
              placeholder="Address"
              value={values.address}
              onChange={(e) => handleField("address", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <textarea
              className={`form-control ${values.detail ? "is-valid" : ""}`}
              placeholder="Other Details"
              value={values.detail}
              onChange={(e) => handleField("detail", e.target.value)}
              rows="3"
            />
          </div>
          {/* Display the status message (Success/Error) */}
          {/* <Status type={values.status.type} message={values.status.message} /> */}
          {values.status.type === "error" && <div className="alert alert-danger mt-2">{values.status.message}</div>}
          <div className="row sticky-bottom-drawer mb-3">
            <div className="col-md-12 d-grid">
              <button type="submit" className="btn btn-app py-3 fs-5 fw-bold text-uppercase" disabled={values.loading || values.photo.loading}>
                <b>{values.loading ? <span className="spinner-border spinner-border-sm me-2"></span> : "Add"}</b>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    {isSuccessMessageVisible  && <div style={{
      "position": "absolute",
      "top": "50%",
      "left": "50%",
      "transform": "translate(81%, 390%)",
      "zIndex": "999",
      "display": "flex",
      "justifyContent": "center",
      "alignItems": "center"
    }}>
     <Status type={"success"} message={"Account created successfully!"} />
    </div>}
    </>
  );
};

export default NewAccount;
