import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { FaFilePdf } from "react-icons/fa";
import ModalImage from "react-modal-image";
import { uploadImage } from "services/profile.service";

const FilePicker = forwardRef((props,ref) => {
  const [upload, setUpload] = useState({ state: false, file: null });
  const [values, setValues] = useState({errormsg: "",preview: null,});
  const [progress,setProgress] = useState(0);

  // Expose the resetFileState function to the parent component
  useImperativeHandle(ref, () => ({
    resetFileState
  }));

  const removeUpload = (e) => {
    e.preventDefault();
    props.onPick("attachment",{file:null,url:"",loading:false});
    resetFileState();
  };

  const resetFileState = () => {
    setUpload({ state: false, file: null });
    setValues({ preview: null, errormsg: "" });
  };

  function offErrorAfterInterval(){
    setTimeout(() => {
      setValues({ errormsg: "", preview: null });
    }, 2000);
  }

  const handleFileChange = async(e) => {
    e.preventDefault();
    const file = e.target.files[0];


    try {
      
      if (file) {
        // Validate file type (image or PDF)
        const allowedTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"];
        if (!allowedTypes.includes(file.type)) {
          setValues({
            errormsg: "Only Image or PDF files are allowed.",
            preview: null,
          });
          offErrorAfterInterval();
          return;
        }
    
        // Validate file size (10MB limit)
        if (file.size <= 10485760 && e.target.files.length === 1) {
          setUpload({ state: true, file });
          const objectUrl = URL.createObjectURL(file);
          setValues({errormsg: "",preview: objectUrl});
          props.onPick("attachment",{file:file,url:objectUrl,loading:true});
          const result = await uploadImage(file,setProgress);
          props.onPick("attachment", { url: result._url, file: file, loading: false });
        } else if (e.target.files.length > 1) {
          setValues({ errormsg: "Only one file allowed", preview: null });
          props.onPick("attachment", { url: null, file: null, loading: false });
          offErrorAfterInterval();
        } else {
          setValues({ errormsg: "File exceeds the size limit of 10MB.", preview: null });
          props.onPick("attachment", { url: null, file: null, loading: false });
          offErrorAfterInterval();
        }
      }
    } catch (error) {
      console.log("Error while uploading image:",error);
      if(error.message === "Filename contains invalid characters."){
        setValues({ errormsg: "Filename contains invalid special characters (),=+-", preview: null });
      }else{
        setValues({ errormsg: "Try Again, File Upload Failed", preview: null });
      }
      props.onPick("attachment", { url: null, file: null, loading: false });
      offErrorAfterInterval();
    } finally{
      setProgress(0);
    }
  
  };

    // UI component to show progress bar during upload
    const renderProgressBar = (progress) => { 
      return <div className="progress my-2 mb-3">
        <div
          className="progress-bar progress-bar-striped bg-danger"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    };
  

  return (
      <div className="upload-bill-container">
        {/* Hidden file input */}
        <input
          type="file"
          id="upload_bills"
          className="d-none"
          onChange={handleFileChange}
          accept="image/*,application/pdf"
          aria-label="Upload Bill"
        />


        {/* File upload label (styled as a button) */}
      <label htmlFor="upload_bills" className="btn btn-outline-app">
        <span className="my-auto">Upload Bill </span>
        <i className="bi bi-paperclip"></i>
      </label>
      <br/>
        {/* Progress bar */}
        {props.loading && renderProgressBar(progress)}

        {/* Preview Section */}
        {values.preview &&  (
        <div className="position-relative mt-1" >
          {upload.file.name?.includes(".pdf") ? (
            <div>
            <FaFilePdf
              size={200}
              className="uploadbillpreview mt-2 rounded text-danger text-decoration-none"
              title="Download PDF"
              />
              </div>
          ) : (
              <ModalImage
                small={values.preview}
                large={values.preview}
                showRotate={true}
                hideDownload={true}
                className="uploadbillpreview position-relative mt-2 rounded shadow border border-2"
              />
          )}

          {/* Remove button */}
         {!props.loading &&  <a onClick={removeUpload}
            className="btn btn-sm btn-app rounded-circle position-absolute top-0 " style={{left:"21%"}}>
            <i className="bi bi-x-lg"></i>
          </a>}
        </div>
      )}


        {/* Error message */}
        {values.errormsg && (
          <div className="alert alert-danger mt-3 d-flex align-items-center" role="alert">
            <i className="bi bi-exclamation-circle-fill me-2"></i>
            {values.errormsg}
          </div>
        )}
      </div>
  );
});

export default FilePicker;
