import React, { useState, useEffect, useRef } from "react";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { accountAPI, reminderAPI } from "services";
import Account from "./Account";
import ExpandableButton from "components/ExpandableButton";
import useUniversalStore from "store/universalStore";
import Filters from "./Filters";
import { ConfirmationModal, Loader } from "components";

const KhataList = () => {
  const { accounts, write, accountId, isReminderChanged } = useUniversalStore((state) => state);
  const [selectedId, setSelectedId] = useState(null);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const listRef = useRef(null); // Reference to the list container

  useEffect(() => {
    if (filteredAccounts.length > 0) {
      setLoading(false);
    }
  }, [filteredAccounts, isReminderChanged]);

  const goToKhata = (_id, account) => {
    // Save the current scroll position before updating the state
    const scrollPosition = listRef.current.scrollTop;
    setSelectedId(_id);
    write({ 
      accountId: _id,
      accountDetails: account
     }); // Update the accountId in the global state

    // Restore the scroll position after the state update
    setTimeout(() => {
      listRef.current.scrollTop = scrollPosition;
    }, 0);
  };

  useEffect(() => {
    if (accountId) setSelectedId(accountId);
  }, [accountId]);

  const closeAccount = async (e) => {
    e.preventDefault();
    const account = accounts.find(account => account.id === selectedId);
    if (account.get("balance") == 0) {
      const result = await accountAPI.closeAccount(account.id);
      if (result) {
        const accounts = await accountAPI.getAccounts(); // Fetch updated accounts list

        // Set the updated accounts list in global state & reset the selected account
        write({ accounts: accounts, accountId: null });
        setSelectedId(null);
      }
    } else {
      alert("Can't delete active account ");
    }
  };

  // console.log("list scroll",listRef.current.scrollTop);
  

  const accountList = () => {
    if (loading) return <Loader />;
    return (
      <ul className="list-group list-group-flush overflow-scroll scrollbar-design" ref={listRef}>
        {filteredAccounts?.map((account, rowId) => (
          <Account
            key={rowId}
            account={account}
            isSelected={account.id === selectedId}
            onSelect={() => {
              if (!account.get("cancelled")) {
                goToKhata(account.id, account);
              }
            }}
          />
        ))}
      </ul>
    );
  };

  if (!filteredAccounts) return <Loader />;

  return (
    <>
      <div className="d-flex flex-column vh-100">
        <Filters setFilteredAccounts={setFilteredAccounts} setLoading={setLoading} />
        {accountList()}
      </div>
      <div className="fixed-bottom" style={{ width: 300, marginLeft: 75, paddingBottom: 50, paddingRight: 0, opacity: 0.85 }}>
        <ExpandableButton
          title="Add Account"
          clickForm="addAccountDrawer"
          icon={faUserPlus}
        />
      </div>
      {/* <ConfirmationModal id="closeAccountModal" onClick={closeAccount} /> */}
    </>
  );
};

export default KhataList;