// export default MonthView;
import React, { useEffect, useState, useCallback } from "react";
import Calendar from "react-calendar";

const MonthView = (props) => {
  const { onDateChange, display, setAnimation, month } = props;
  const currDate = new Date();
  const [date, setDate] = useState(currDate);
  const [showCalendar, setShowCalendar] = useState(false);
  const [nextMonthAccessible, setNextMonthAccessible] = useState(true);
  const month_name = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Update date state when month prop changes
  useEffect(() => {
    if (month && month.getTime() !== date.getTime()) {
      setDate(month);
    }
  }, [month, date]);

  // Determine if the next month is accessible
  useEffect(() => {
    const isCurrentMonth = currDate.getMonth() === date.getMonth();
    setNextMonthAccessible(!isCurrentMonth);
  }, [date]);

  const handleDateChange = useCallback((newDate) => {
    // onDateChange(newDate.getFullYear(), newDate.getMonth());
    onDateChange(newDate)
    setDate(newDate);
    setShowCalendar(false);
  }, []);

  const addMonth = useCallback(() => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1); // Add 1 month
    setDate(newDate);
    onDateChange(newDate);
    // onDateChange(newDate.getFullYear(), newDate.getMonth());
    setAnimation("animate__animated animate__slideInRight fast-animation");
  }, [date, onDateChange]);

  const subMonth = useCallback(() => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - 1); // Subtract 1 month
    setDate(newDate);
    onDateChange(newDate)
    // onDateChange(newDate.getFullYear(), newDate.getMonth());
    setAnimation("animate__animated animate__slideInLeft fast-animation");
  }, [date, onDateChange]); 

  const isFutureMonth = (month, year) => {
    const currMonth = currDate.getMonth();
    const currYear = currDate.getFullYear();
    return year > currYear || (year === currYear && month > currMonth);
  };
  
  return (
    <div className={display ? "khataFilter rounded-3 d-flex"
          : "khataFilter rounded-3 d-none"}>
      <div className="h-100 primary d-flex ms-auto rounded-2">
        <button className="btn btn-sm bg-app fs-4 text-white" onClick={subMonth}>
          {/* <i className="bi bi-chevron-left"></i> */}
          <i class="bi bi-arrow-left-circle-fill"></i>
        </button>
        <label
          className="primary text-white h4 my-auto rounded"
          onClick={() => setShowCalendar((prev) => !prev)}
        >
          {month_name[date.getMonth()] + `'` + date.getFullYear()}
        </label>
        {showCalendar && (
          <div className="calendar-popup">
            <Calendar
              onChange={handleDateChange}
              value={date}
              view="year"
              maxDetail="year"
              showNeighboringMonth={false}
              calendarType="US"
              maxDate={currDate}
              tileClassName={({ date }) => {
                const month = date.getMonth();
                const year = date.getFullYear();
                if (isFutureMonth(month, year)) {
                  return "disabled-month";
                }
                return null;
              }}
            />
          </div>
        )}
        <button className="btn btn-sm border-0" onClick={addMonth} disabled={!nextMonthAccessible}>
          {/* <i className="bi bi-chevron-right"></i> */}
          {nextMonthAccessible ? <i class="bi bi-arrow-right-circle-fill fs-4 text-white"></i> :<i class="bi bi-arrow-right-circle fs-4 text-white"></i>}
        </button>
      </div>
    </div>
  );
}

export default MonthView;