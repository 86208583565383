import { useState } from "react";
import { BsCamera } from "react-icons/bs";
import { FaSpinner, FaUserEdit } from "react-icons/fa";
import { accountAPI } from "services";
import useUniversalStore from "store/universalStore";
import { uploadImage } from "services/profile.service";
import ModalImage from "react-modal-image";

const ProfilePhoto = ({ url, loading, updateField}) => {
  const accountId = useUniversalStore((state) => state.accountId);
  const [values, setValues] = useState({ errormsg: "", preview: url || null });
  const [progress, setProgress] = useState(0); // Add progress to state

  const handleProfileSelect = async (e) => {
    e.preventDefault();
    const newfile = e.target.files[0];

    // return if no file selected
    if (!newfile) return;
  
    // Check if the selected file is an image by inspecting the MIME type
    if (!newfile.type.startsWith("image/")) {
      failure("Only image files are allowed.");
      updateField("photo", { url: null, file: null, loading: false });
      setProgress(0);
      return; // Prevent further processing
    }
  
    try {
      if (newfile.size <= 2097152 && e.target.files.length <= 1) {
        updateField("photo", { file: newfile, loading: true });
        // let currentProgress = 0; // Local variable for progress
        // const progressInterval = setInterval(() => {
        //   if (currentProgress < 100) {
        //     currentProgress += 20; // Increment progress (adjust step if needed)
        //     if (currentProgress >= 100) {
        //       clearInterval(progressInterval); // Stop the interval when progress reaches 100
        //     }
        //     setProgress(currentProgress); // Update state with progress
        //   }
        // }, 500); // Update progress every second
        const objectUrl = URL.createObjectURL(newfile);
        success(objectUrl, newfile);
        updateField("photo", { url:objectUrl, file: newfile, loading: true });
        const result = await uploadImage(newfile,setProgress);
        updateField("photo", { url: result._url, file: newfile, loading: false });
        setProgress(0);    
      } else if (e.target.files.length > 1) {
        failure("Only one file allowed");
        updateField("photo", { url: null, file: null, loading: false });
      } else {
        failure("File exceeds the size limit of 2MB.");
        updateField("photo", { url: null, file: null, loading: false });
      }
    } catch (error) {
      updateField("photo", { url: null, file: null, loading: false });
      console.log("error while uploading image", error.message);
      setProgress(0);
      if(error.message === "Filename contains invalid characters."){
        failure(error.message)
      }else{
        failure("Try again, Image upload failed");
      }
    }
  };
  
  const success = (objectUrl, file) => {
    setValues({
      errormsg: "",
      preview: objectUrl,
    });
  };

  const failure = (message) => {
    // console.log("error message",message);
    updateField("photo", { url: null, file: null });

    console.log("message",message);
    setProgress(0);
    
    setValues((prevValues) => ({
      ...prevValues,
      errormsg: message,
      preview: prevValues.preview,
    }));

    setTimeout(() => {
      setValues((prevValues) => ({
        ...prevValues,
        errormsg: "",
      }));
    }, 5000);
  };

  const removeImage = async () => {
    try {
      updateField("photo", { url: null, file: null });
      setValues({ errormsg: "", preview: null });
      const account = await accountAPI.getAccountById(accountId);

      if (account) {
        account.set("photo", null);
        await account.save();
      }
    } catch (error) {
      console.log("Error while removing link", error);
    }
  };

  // UI component to show camera icon to select image
  const renderCameraIcon = () => (
    <label htmlFor="photo">
      <div
        className="position-relative bg-white rounded-circle"
        style={{ top: "-37px", left: "37px", padding: "5px" }}
      >
        <BsCamera
          size={30}
          role="button"
          className="rounded-circle border border-1 border-danger text-danger"
          style={{ padding: 5 }}
        />
      </div>
    </label>
  );

  // UI component to show edit and remove/change image options when an image is set
  const renderEditOptions = () => (
    <label key="Imagedropdown">
      <div
        className="position-relative bg-white rounded-circle"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ top: "-37px", left: "37px", padding: "5px" }}
      >
        <FaUserEdit
          size={30}
          role="button"
          className="rounded-circle border border-1 border-danger text-danger"
          style={{ padding: 5 }}
        />
      </div>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li>
          <a className="dropdown-item w-100" onClick={removeImage}>
            Remove image
          </a>
        </li>
        <li>
          <label className="w-100" htmlFor="photo">
            <a className="dropdown-item">Change image</a>
          </label>
        </li>
      </ul>
    </label>
  );

  // UI component to show progress bar during upload
  const renderProgressBar = (progress) => { 
    return <div className="progress my-2 mb-3">
      <div
        className="progress-bar progress-bar-striped bg-danger"
        role="progressbar"
        style={{ width: `${progress}%` }}
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  };

  // UI component to show error message
  const renderErrorMessage = () => (
    values.errormsg && <div className="alert alert-danger mt-2">{values.errormsg}</div>
  );


  // show loader while uploading image
  const showLoader = ()=>{
    return (
      <label>
        <div
          className="position-relative bg-white rounded-circle"
          style={{ top: "-37px", left: "37px", padding: "5px" }}
        >
          <FaSpinner
            size={30}
            role="button"
            className="spinner-border rounded-circle border border-1 border-danger text-danger"
            style={{ padding: 5 }}
            />
        </div>
      </label>
    )
  }

  return (
    <>
      <div>
        {/* this image is used to handle broken link */}
        <img
          src={url || "https://placehold.co/150x150"}
          className="d-none"
          onError={(e) => {
            failure("Select valid image format");
          }}
        />
        {url?<div style={{ 
          width: "150px", 
          height: "150px"
        }} className="">
      <ModalImage
        small={url || "https://placehold.co/150x150"}
        large={url || "https://placehold.co/150x150"}
        // showRotate={true}
        hideZoom={true}
        hideDownload={true}
        className="rounded-circle border border-1 border-danger profileImg img-fluid" 
        />
      </div>:
       <div style={{
        width: "150px",
        height: "150px"
      }} className=" rounded-circle border border-1 border-danger">
        <img
          src={"https://placehold.co/150x150"}
          className="img-fluid rounded-circle border border-1"
        />
      </div>}
        {loading ? showLoader() :!url ? renderCameraIcon() : renderEditOptions()}


        <input
          id="photo"
          type="file"
          accept="image/*"
          className="d-none"
          onChange={handleProfileSelect}
        />
        {loading && renderProgressBar(progress)}
        {renderErrorMessage()}
      </div>
    </>
  );
};

export default ProfilePhoto;

