import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowCircleLeft, faArrowLeft, faImage } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';
import { api, transactionAPI } from 'services';
import {
  Loader, Status
} from "components";
import useUniversalStore from "store/universalStore";
import { formatMoney } from "utils";
import DateTimePicker from "react-datetime-picker";
// import txnaudio from "../images/google_pay-[AudioTrimmer.com].mp3"

const EditTxn = (props) => {
  const { handleChangeMonth } = props;
  const transactionId = useUniversalStore(state => state.transactionId);
  const accountId = useUniversalStore(state => state.accountId);
  const write = useUniversalStore((state) => state.write);

  const initialState = {
    amount: 0,
    accountName: "",
    details: "",
    date: new Date(),
    loading: true,
    status: { type: null, message: "" }
  }
  const [values,setValues] = useState(initialState);
  const MAX_AMOUNT = 10000000;

  useEffect(() => {
    const offCanvas = document.getElementById("editTxnDrawer");
    if(!offCanvas) return;

    const handleHidden = () => {
      console.log("hiiden canvas");
      
      setValues(initialState);
    };

    const handleShown = () => {
      fetchTransactionDetails();
    };

    offCanvas.addEventListener("hidden.bs.offcanvas", handleHidden);
    offCanvas.addEventListener("shown.bs.offcanvas", handleShown);

    return () => {
      //remove event listeners when offcanvas is hidden
      offCanvas.removeEventListener("hidden.bs.offcanvas", handleHidden);
      offCanvas.removeEventListener("shown.bs.offcanvas", handleShown);
    };

  }, [transactionId]);

  const fetchTransactionDetails = async () => {
    const transaction = await transactionAPI.getTransactionById(transactionId);
    setValues((prev) => ({
      ...prev,
      amount: transaction.get("amount"),
      accountName: transaction.get("accountId")?.get("name") || "",
      details: transaction.get("detail") || "",
      date: transaction.get("date"),
      loading: false,
      status:{type:"",message:""}
    }));
  }

  const updateField = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  }

  const submitEditTransaction = async (e) => {
    e.preventDefault();
    setValues(prev => ({ ...prev, loading: true }));
    
    try {
      
      //Set time along with date to order transactions
      values.date.setHours(values.date.getHours(),
      values.date.getMinutes(), values.date.getSeconds());

      //Update selected account data
      await transactionAPI.updateTransaction(transactionId,values.amount,values.details,values.date);
      const updatedTransactionDate = new Date(values.date)
      handleChangeMonth(updatedTransactionDate)
      //Update transactions in global store
      write({
        transactions: await transactionAPI.getTransactionsByAccountIdWithMonth(accountId, values.date),
        transactionId: null
      });

      //Reset form and close offcanvas
      setTimeout(() => {
        setValues((prev)=>({ 
          ...prev,
          status: { type: "success", message: "Transaction Updated Successfully!" } 
        }));
      }, 100);
    } catch (error) {
      setValues(prev =>({
        ...prev,
        status: { type: "error", message: error.message },
      }));
    }
  };

  const failure = (msg) => {
    setValues(prev => ({
      ...prev,
      status:{ type: "error", message: msg },
    }));
    setTimeout(() => {
      setValues((prev)=> ({
        ...prev,
        status:{ type: null, message: '' },
      }))
    }, 2500);
  };

  const success = (msg) => {
    setValues(prev => ({
      ...prev,
      status:{ type: "success", message: msg },
    }));
    setTimeout(() => {
      setValues((prev)=> ({
        ...prev,
        status:{ type: null, message: '' },
      }))
    }, 2500);
  };

  const amountChangeHandler = (e) => {
    e.preventDefault();
    let amountValue = e.target.value;
  
    // Remove spaces, commas, and other special characters (except minus and dot)
    amountValue = amountValue.replace(/[₹, ]/g, "");
  
    // Check if the value starts with a minus or plus sign
    const isNegative = amountValue.startsWith('-');
    const isPositive = amountValue.startsWith('+');
  
    // Remove any sign to handle the numeric part
    let numericValue = amountValue.replace(/[^0-9.]/g, "");
  
    // If the numeric part is empty or NaN, reset it to 0
    if (numericValue === "" || isNaN(numericValue)) {
      numericValue = "0";
    }
  
    // If the value is cleared or the numeric value is empty, reset to 0
    if (amountValue === "" || numericValue === "0") {
      setValues((prev) => {
        let tempValue = { ...prev };
        tempValue.amount = 0;
        return tempValue;
      });
      return;
    }
  
    // Check if the value exceeds MAX_AMOUNT
    const isValidAmount = parseFloat(numericValue) <= MAX_AMOUNT;
  
    if (isValidAmount) {
      setValues((prev) => {
        let tempValue = { ...prev };
  
        // If it's negative, keep the negative sign
        if (isNegative) {
          tempValue.amount = -parseFloat(numericValue);
        } else if (isPositive || numericValue !== "0") {
          tempValue.amount = parseFloat(numericValue);
        }
  
        return tempValue;
      });
    } else {
      failure("Enter Amount less than " + formatMoney(MAX_AMOUNT));
    }
  };
    
  return (
    <>
    <div className="offcanvas offcanvas-end text-danger" tabIndex="-1" id="editTxnDrawer" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Edit Transaction</h3>
        <button type="button" className="btn-close text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body container-fluid">
        <div className="bg-white mb-5 row col-12">
          {values.loading ? <Loader /> :
            <form onSubmit={submitEditTransaction}>
              <div className="row py-3">
                <div className="col-md-6">
                  <input 
                    type="text" className="seventh-step form-control" 
                    placeholder="Amount"
                    id="editAmount"
                    value={formatMoney(values.amount)} 
                    onChange={amountChangeHandler}
                    required />
              </div>
              <div className="col-md-6 border-present mt-4 mt-md-0">
                {/* <DatePicker 
                  className="w-100"
                  format="dd-MMM-yyyy"
                  clearIcon={null}
                  calendarIcon={null}
                  value={values.date} 
                  onChange={(dateValue) => updateField("date", dateValue)}
                  maxDate={new Date()} /> */}
                  <DateTimePicker
                    className="w-100 p-2"
                    calendarIcon={<i className="bi bi-calendar-date fs-5"></i>}
                    clearIcon={null}
                    maxDate={new Date()}
                    disableClock={true}
                    format="dd-MM-y"
                    onChange={(dateValue) => updateField("date", dateValue)}
                    value={values.date}
                  />
              </div>
            </div>
            <div className="py-3">
              <input type="text" 
                className="form-control"
                placeholder="Account Name"
                disabled 
                value={values.accountName} />
            </div>
            <div className="py-3">
              <textarea type="text" 
                className="form-control"
                placeholder="Details"
                maxLength="50" 
                value={values.details}
                onChange={(e) => updateField("details", e.target.value)} />
            </div>
           {values.status.type && <Status type={values.status.type} message={values.status.message} />}
            <div className="row sticky-bottom-drawer my-3">
                <div className="col-md-12 d-grid">
                  <button type="submit" 
                    className="btn btn-app block py-3" data-bs-dismiss="offcanvas">
                    <b className="fs-5 text-uppercase">Update</b>
                  </button>
                </div>
              </div>
            </form>
          }
        </div>
      </div>
    </div>
      <div style={{
        position: "absolute",
        top: "70vh",
        left: "50%",
        transform: "translate(-50%, -50%)", 
        zIndex: 999,
        display: "flex",
        justifyContent: "center", 
        alignItems: "center"
      }}>
        {values.status.type && <Status type={values.status.type} message={values.status.message} />}
      </div>

    {/* <Status type={values.status.type} message={values.status.message} /> */}
    </>
  )
}
export default EditTxn;
