const creatableStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "#7e4e60" : "#ccc",
      boxShadow: state.isFocused ? `0 0 0 2px #7e4e60` : "none",
      borderRadius: "8px",
      fontSize: "16px",
      paddingLeft: "8px",
      fontWeight: "500",
      transition: "all 0.3s ease",
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "red",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      zIndex: 100,
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7e4e60",
        border: "2px solid #fff",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
      },
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      maxHeight: "200px",
      backgroundColor: "white",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#7e4e60",
        borderRadius: "8px",
        border: "2px solid #fff",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1",
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isFocused || !state.isSelected ? "#333" : "white",
      fontSize: "16px",
      fontWeight: "500",
      backgroundColor: state.isFocused ? "#FFCCCB" : state.isSelected ? "#7e4e60" : "white",
      padding: "10px",
      cursor: "pointer",
      borderRadius: "6px",
      "&:active": {
        backgroundColor: "#7e4e60",
        color: "white",
      },
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "#7e4e60",
      fontSize: "16px",
      fontStyle: "italic",
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: "#333",
      fontSize: "16px",
      fontWeight: "500",
      padding: "8px",
      marginLeft: "-7px",
    }),
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      color: "#7e4e60",
      cursor: "pointer",
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      color: "#7e4e60",
      cursor: "pointer",
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "#7e4e60",
    }),
  }


export default creatableStyle;