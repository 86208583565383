import useUniversalStore from 'store/universalStore';
import MonthView from './MonthView';
import { toggleOffCanvas } from "utils";

const Index = ({ account, handleNewDate, setAnimation, month, hideClosed, handleToggle }) =>{
  return (
    <div className="bg-app text-white py-2"
      style={{ display: "flex", justifyContent: "space-between" }}>
      <div role="button"
        onClick={() => toggleOffCanvas('editAccountDrawer')}>
        <h3 className="text-left d-flex">
          {account?.get("photo") ? (
            <div className="profile-img mx-3">
              <img src={account.get("photo")}
                alt="Profile Image"
                className="img-fluid"
                onError={(e) => {
                  e.target.src = "https://placehold.co/150x150"; // Set the fallback image URL on error
                }} />
            </div>
          ) : (
            <i className="bi bi-person-circle mx-3"
              style={{ fontSize: "45px", color: "white" }}></i>
          )}

          <div className="d-flex flex-column my-auto me-md-auto">
            {account ? account.get("name").substring(0, 30) : ""}
            <span style={{ fontSize: "14px" }}>
              View Profile
            </span>
          </div>
          <span>
          </span>
        </h3>
      </div>
      <div className='d-flex justify-content-center align-items-center'>
        <span className='cursor'>
        {
          hideClosed ? 
            <i class="bi bi-eye-fill fs-4" onClick={()=>handleToggle(false)}></i> 
            :  <i class="bi bi-eye fs-4" onClick={()=>handleToggle(true)}></i> 
        }
        </span>
        <MonthView
        month={month}
        setAnimation={setAnimation}
        onDateChange={handleNewDate}
        display={true} />
      </div>
    </div>
  )
} 



export default Index;