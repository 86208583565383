const Status = ({ type, message }) => {
    if(type == null){
        return <div className="py-2"></div>;
    }
    else if(type == "error"){
        return (
            <div className="form-group text-start animate__animated animate__fadeOut animate__delay-2s">
                <div className="form-check-label alert alert-danger alert-dismissible fade show text-capitalized " role="alert">
                    <i className="bi bi-exclamation-circle-fill"></i> {message}

                </div>
            </div>
        );
    }
    return (
        <div className="form-group text-start animate__animated animate__fadeOut animate__delay-2s">
            <div className="form-check-label alert alert-success text-capitalized alert-dismissible fade show" role="alert">
                <i className="bi bi-check-circle-fill"></i>{' '}{message}
            </div>
        </div>
    )
}
export default Status;