import { useState } from "react";
import { auth, reminderAPI } from "services";
import useUniversalStore from "store/universalStore";

function ReminderAlert(props) {
  const [showReminder, setShowReminder] = useState(true);
  const {write} = useUniversalStore((state)=>state);
  const { account, reminder, setOverlayVisible } = props;
  const currentUser = auth.fetchUser();
  const id = account.id;

  const handleSendReminder = () => {
    const num = account.get("phone");
    const name = currentUser.get("name");
    const bal = -account.get("balance");
    const line1 = "Payment Reminder : ";
    const line2 = `Balance *₹${bal}* due to *${name}*`;
    const link = `Ledger : ${process.env.NODE_ENV == "development" ? "http://localhost:3000" : process.env.REACT_APP_URL}/p/${id}`;
    const line3 = `- BahiKhata`;
    window.open(`https://wa.me/${num}?text=${line1}%0a${line2}%0a${link}%0a%0a${line3}`,"_blank");
  };

  const handleClose = async () => {
    await reminderAPI.toggleReminder(reminder?.id);
    setShowReminder(false);
    setOverlayVisible(false);
    write({isReminderChanged:true})
  };

  return (
    <>
      <div className="row position-fixed bottom-0 start-50 my-5 py-3 px-4 w-auto flex-wrap position-sticky" style={{bottom:0}}>
        { showReminder && (
          <div className="alert bg-white h-100 p-4" role="alert" style={{marginTop:"-200px"}}>
            <div className="text-center">
              <h2>Payment Overdue !!</h2>
                <hr />
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="btn btn-secondary me-2 py-2" onClick={handleClose}>
                  Close
                </div>
                <div onClick={handleSendReminder} className="btn btn-app border-app">
                  Send Reminder
                </div>
              </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ReminderAlert;