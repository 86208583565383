import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { auth, profileAPI, accountAPI, transactionAPI } from "services";

const universalStore = (set, get) => ({
  accountId: null,
  transactionId: null,
  user: null,
  profile: null,
  accounts: [],
  transactions: [],
  isReminderChanged:false,
  accountDetails:null,
  
  // activeComponent:"edit",
  fetchInitialData: async() => {
    try {
      const [user, accounts, profile] = await Promise.all([
        auth.fetchUser(),
        accountAPI.getAccounts(),
        profileAPI.getProfile(),
      ]);

      //Update Global State
      set({ user, accounts, profile });
    } catch(error) {
      console.error("Failed to fetch initial data", error);
    }
  },

  write: (obj) => {
    if (typeof obj === 'function') {
      const result = obj(get()); // Get the current state and pass it to the function
      set(result);
      return;
    }

    //Update Global State
    set(state => ({ ...state, ...obj }));
  },
});

const useUniversalStore = create(
  devtools(universalStore, { name: "Universal Store" })
);

export default useUniversalStore;
